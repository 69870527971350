export const REGISTER_TITLE_FALLBACK = 'The number 1 student discount site'

export const REGISTER_BODY_FALLBACK =
  "Enjoy thousands of dreamy deals on our top-rated website. Whether you're at home or on the go, get instant discounts for your fave brands right here."

export const SIGN_UP_FALLBACK = 'Sign me up'

export const HOW_IT_WORKS_FALLBACK = 'How does it work?'

export const BUTTON_CLICK_SCHEMA =
  'iglu:com.studentbeans/button/jsonschema/1-0-0'
export const BUTTON_CLICK_STYLE = 'button'
export const BUTTON_CLICK_LOCATION = 'homePageValuePropRegister'
