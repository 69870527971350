import Placeholder from '@components/placeholder'

const ValuePropRegisterPlaceholder = () => {
  return (
    <>
      <div className='mb-5 lg:hidden'>
        <Placeholder
          height='550px'
          width='100%'
        />
      </div>
      <div className='hidden lg:block mb-9'>
        <Placeholder
          height='299px'
          width='100%'
        />
      </div>
    </>
  )
}

export default ValuePropRegisterPlaceholder
