'use client'

import { usePathname } from 'next/navigation'

import Button from '@thebeansgroup/ui/button'
import Typography from '@thebeansgroup/ui/typography'
import TextLink from '@thebeansgroup/ui_ctas/text_link_new'

import { trackUnstructEvent } from '@root/src/tracker/snowplow'

import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'
import { getSignupUrl } from '@helpers/auth'
import { getNextRoute } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'
import { useUserData } from '@hooks/use_user_data'

import {
  BUTTON_CLICK_LOCATION,
  BUTTON_CLICK_SCHEMA,
  BUTTON_CLICK_STYLE,
  HOW_IT_WORKS_FALLBACK,
  REGISTER_BODY_FALLBACK,
  REGISTER_TITLE_FALLBACK,
  SIGN_UP_FALLBACK
} from './constants'
import { shouldShowValuePropRegister } from './helpers'
import ValuePropRegisterPlaceholder from './placeholder'

const ValuePropRegister = () => {
  const { data, loading } = useUserData()
  const { t } = useI18n()
  const pathname = usePathname()
  const country = useCountryCode()

  if (loading) return <ValuePropRegisterPlaceholder />

  if (!shouldShowValuePropRegister(data, loading)) return null

  const handleLinkClick = (text) => {
    trackUnstructEvent(BUTTON_CLICK_SCHEMA, {
      text,
      style: BUTTON_CLICK_STYLE,
      location: BUTTON_CLICK_LOCATION
    })
  }

  const renderTitle = () => {
    return (
      <div
        className='mb-3 font-bold max-w-[550px] lg:max-w-[650px] z-1'
        data-testid='value-prop-title'
      >
        <Typography
          variant='title3'
          component='h2'
          fontWeight='bold'
          className='block lg:hidden'
        >
          {t('d_number_one_site', { fallback: REGISTER_TITLE_FALLBACK })}
        </Typography>
        <Typography
          variant='largeTitle'
          component='h2'
          className='hidden lg:block'
          fontWeight='bold'
        >
          {t('d_number_one_site', { fallback: REGISTER_TITLE_FALLBACK })}
        </Typography>
      </div>
    )
  }

  const renderText = () => {
    return (
      <Typography
        className='max-w-[500px] mb-6 xl:max-w-[650px] z-1 !text-grey-400'
        data-testid='value-prop-text'
      >
        {t('m_enjoy_thousands_of_deals', {
          fallback: REGISTER_BODY_FALLBACK
        })}
      </Typography>
    )
  }

  const renderButtons = () => {
    const registerText = t('a_sign_me_up', { fallback: SIGN_UP_FALLBACK })
    const howItWorksText = t('a_how_it_works', {
      fallback: HOW_IT_WORKS_FALLBACK
    })

    return (
      <div className='flex z-1 items-center'>
        <Button
          onClick={() => handleLinkClick(SIGN_UP_FALLBACK)}
          href={getSignupUrl(country, pathname)}
          component='a'
          className='mr-4 lg:mr-7'
        >
          <span data-testid='value-prop-register-cta'>{registerText}</span>
        </Button>
        <span data-testid='value-prop-how-it-works'>
          <TextLink
            onClick={() => handleLinkClick(HOW_IT_WORKS_FALLBACK)}
            href={getNextRoute('how.it.works', { country })}
            text={howItWorksText}
          />
        </span>
      </div>
    )
  }

  const renderImages = () => {
    return (
      <div className='absolute z-0 w-full h-full pointer-events-none left-0 right-0 top-0 lg:h-auto lg:top-auto lg:leading-[0]'>
        <div className='absolute z-0 leading-[0] flex-grow-0 flex-shrink-0 w-[200px] h-[151px] top-0 left-[-85px] lg:top-[50px] lg:w-[264px] lg:h-[199px] lg:left-[-120px] xl:top-0 xl:w-[396px] xl:h-[299px] xl:left-[-160px]'>
          <SafeImage
            src={withAssetPrefix('images/value_prop_register/left_graphic.png')}
            fill
            alt=''
          />
        </div>
        <div className='absolute z-0 leading-[0] flex-grow-0 flex-shrink-0 w-[298px] h-[209px] bottom-0 right-[-165px] lg:bottom-auto lg:w-[362px] lg:h-[265px] lg:right-[-210px] xl:w-[471px] xl:h-[345px]'>
          <SafeImage
            src={withAssetPrefix(
              'images/value_prop_register/right_graphic.png'
            )}
            fill
            alt=''
          />
        </div>
      </div>
    )
  }

  return (
    <section className='relative flex flex-col items-center mb-5 text-center py-[150px] lg:static lg:mb-9 lg:py-[44px] '>
      {renderTitle()}
      {renderText()}
      {renderButtons()}
      {renderImages()}
    </section>
  )
}

export default ValuePropRegister
